/*------------------------------------------------------------------[MAIN STYLESHEET]PROJECT:	Portio HTMLVERSION:	1.0.0-------------------------------------------------------------------*/@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900|Yeseva+One&display=swap");body{background:#fff}
.section{padding:100px 0}.preloader-wrap{width:100%;height:100%;position:fixed;top:0;bottom:0;background:#fff;z-index:2000;text-align:center;height:100vh}
.preloader-wrap .inner{position:absolute;height:170px;width:170px;left:50%;top:50%;transform:translate(-50%,-50%);border:1px solid #5d78ff;border-top:5px solid #5d78ff;border-radius:50%;animation:spin 1s infinite linear}
@media(max-width:991px){.preloader-wrap .inner{height:150px;width:150px}}.preloader-wrap .percentage{z-index:100;color:#5d78ff;opacity:1;font-weight:600;font-family:"bebasbold";font-size:60px;letter-spacing:5px;line-height:1;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}
@media(max-width:991px){.preloader-wrap .percentage{font-size:50px}}@keyframes spin{0%{transform:translate(-50%,-50%) rotate(0)}
100%{transform:translate(-50%,-50%) rotate(360deg)}}.navbar{padding:40px 0;background:transparent;transition:all .3s ease}
@media(max-width:991px){.navbar{background:#fff}}@media(max-width:991px){.navbar{padding:15px 20px;margin:10px 10px 0;border-radius:5px}
}@media(max-width:991px){.navbar .navbar-brand img{width:80%}}.navbar .main-navigation{margin:0 auto}
.navbar .main-navigation .nav-item .nav-link{color:#282f49;font-family:"Poppins",sans-serif;padding:0 15px;transition:all .3s ease;font-weight:500}
.navbar .main-navigation .nav-item .nav-link:hover{color:#5d78ff}@media(max-width:991px){.navbar .main-navigation .nav-item .nav-link{text-align:center;padding:15px 0;cursor:pointer}
}.navbar .main-navigation .nav-item .active{color:#5d78ff}.navbar .hire_button{background:#5d78ff;padding:15px 30px;color:#fff;text-decoration:none;border-radius:7px;font-size:16px;font-family:"Poppins",sans-serif;font-weight:400;border:1px solid transparent;transition:all .3s ease;transform:scale(1)}
.navbar .hire_button:hover{background:#425fee;box-shadow:0 8px 20px rgba(56,87,241,0.3);transform:scale(1.03)}
@media(max-width:991px){.navbar .hire_button{text-align:center}}.navbar.nav__color__change{background:#fff;padding:20px 0;box-shadow:0 10px 20px 0 rgba(50,65,141,0.1)}
@media(max-width:991px){.navbar.nav__color__change{padding:15px 20px}}@media(max-width:991px){.navbar .navbar-toggler{outline:0;padding:0}
.navbar .navbar-toggler-icon{height:2px;width:25px;transition:all .2s;background:#5d78ff;display:block}
.navbar .navbar-toggler-icon:not(:last-child){margin-bottom:5px}.navbar .navbar-toggler-icon:nth-child(1){transform:rotate(45deg);transform-origin:10% 10%}
.navbar .navbar-toggler-icon:nth-child(2){opacity:0;filter:alpha(opacity=0)}.navbar .navbar-toggler-icon:nth-child(3){transform:rotate(-45deg);transform-origin:10% 90%}
.navbar .navbar-toggler.collapsed .navbar-toggler-icon:nth-child(1){transform:rotate(0)}
.navbar .navbar-toggler.collapsed .navbar-toggler-icon:nth-child(2){opacity:1;filter:alpha(opacity=1)}
.navbar .navbar-toggler.collapsed .navbar-toggler-icon:nth-child(3){transform:rotate(0)}
}.hero{padding:200px 0;position:relative;display:flex;align-items:center;justify-content:center}
@media(max-width:991px){.hero{padding:150px 0 30px 0}}.hero_background-svg{position:absolute;top:0;left:0;z-index:-1;width:65%}
.hero_background-svg svg{width:100%;height:100%}.hero_footer-svg{position:absolute;bottom:20px;left:-130px;animation:move_top 5s infinite linear}
@media(max-width:991px){.hero_footer-svg{display:none}}.hero_content{position:relative}
@media(max-width:991px){.hero_content{text-align:center;margin-bottom:60px}}.hero_content .hero_blob{position:absolute;z-index:-1;top:-45%;left:-45%;height:440px;width:440px}
.hero_content .hero_blob svg{width:100%;position:relative}@media(max-width:991px){.hero_content .hero_blob{display:none}
}.hero_content span{font-size:24px;font-family:"Poppins",sans-serif;color:#282f49;position:relative;display:block;margin:0 0 20px 30px}
@media(max-width:991px){.hero_content span{display:inline-block}}.hero_content span::before{position:absolute;content:"";height:2px;width:20px;top:50%;left:-20px;transform:translateX(-50%);background:#282f49}
.hero_content h1{margin:0 0 30px 0;font-size:60px;font-family:"Yeseva One",cursive;color:#282f49;line-height:70px}
.hero_content p{margin:0 0 50px 0;font-family:"Poppins",sans-serif;color:#282f49;font-size:20px;line-height:30px}
.hero_content a{display:inline-block;padding:20px 50px;font-family:"Poppins",sans-serif;font-size:16px;background:#5d78ff;border-radius:7px;color:#fff;text-decoration:none;border:1px solid transparent;transition:all .3s ease;transform:scale(1)}
.hero_content a:hover{background:#425fee;box-shadow:0 8px 20px rgba(56,87,241,0.3);transform:scale(1.03)}
.hero_figure{width:90%;margin-left:auto;border-radius:20px;position:relative}@media(max-width:991px){.hero_figure{width:100%}
}.hero_figure .figure-svg{position:absolute;top:-55px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);z-index:-1;height:150px;animation:move_left 5s infinite linear}
.hero_figure .figure-svg img{height:100%;width:100%}.hero_figure img{height:100%;width:100%}
.hero_figure img.hero_mask_svg{mask:url("../images/hero/hero-mask-svg.png");-webkit-mask-repeat:no-repeat;-webkit-mask-size:contain;-webkit-mask-position:center center}
.hero_figure-popup{position:absolute;bottom:80px;left:0;transform:translateX(-50%);transition:all .3s ease}
@media(max-width:991px){.hero_figure-popup{bottom:-40px;left:50%;transform:translateX(-50%)}
}.hero_figure-popup .thumb{height:160px;width:250px;border:1px solid #707070;border-radius:20px;overflow:hidden;position:relative}
.hero_figure-popup .thumb::before{position:absolute;content:"";top:0;left:0;height:100%;width:100%;background:rgba(0,0,0,0.6);z-index:1}
.hero_figure-popup .thumb img{width:100%;height:100%;object-fit:cover}.hero_figure-popup .popup-button{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);height:60px;width:60px;background:#fff;border-radius:50%;z-index:2;transition:all .3s ease}
.hero_figure-popup .popup-button svg{position:absolute;top:50%;left:55%;transform:translate(-50%,-50%);transition:all .3s ease}
.hero_figure-popup .popup-button:hover{background-color:#5d78ff}.hero_figure-popup .popup-button:hover svg path{fill:#fff}
.hero_figure-popup:hover .popup-button{transform:translate(-50%,-50%) scale(1.1)}
@keyframes move_left{0%{transform:translateX(-50%)}50%{transform:translateX(-57%)}
}@keyframes move_top{0%,100%{transform:translateY(0)}50%{transform:translateY(15px)}
}@media(max-width:1400px){.about{overflow:hidden}}.about_header{margin-bottom:60px}
.about_header span{display:block;font-size:20px;font-family:"Poppins",sans-serif;margin:0 0 30px 0}
.about_header h1{margin:0 0 20px 0;font-size:55px;font-family:"Yeseva One",cursive;color:#282f49;line-height:1}
.about_header p{margin:0;font-size:18px;font-family:"Poppins",sans-serif;color:#7e7e8a;line-height:30px}
.about_content{display:flex;align-items:center;justify-content:center;flex-wrap:wrap}
.about_content-thumb{width:45%;position:relative}@media(max-width:991px){.about_content-thumb{width:100%}
}.about_content-thumb .about-svg{position:absolute;top:-11%;left:-17%;z-index:-1;height:150px;animation:move_top 4s infinite}
@media(max-width:767px){.about_content-thumb .about-svg{top:-7%;left:-16%}}.about_content-thumb .about-svg img{height:100%;width:100%}
.about_content-thumb .about_content-thumb-image img{height:100%;width:100%}.about_content-thumb .about_content-thumb-image img.about_mask_svg{mask:url(../images/about/about-mask-svg.svg);-webkit-mask-repeat:no-repeat;-webkit-mask-size:contain;-webkit-mask-position:center center}
.about_content-inner{width:55%;padding:60px 50px;background:#1b2031;border-radius:20px;margin-left:-60px;position:relative}
@media(max-width:991px){.about_content-inner{padding:25px}}@media(max-width:991px){.about_content-inner{width:100%;margin-left:0;margin-top:30px}
}.about_content-inner-blob{position:absolute;top:-55%;right:-22%;z-index:-1;width:400.369px;height:362.909px}
.about_content-inner-blob svg{width:140%}@media(min-width:1400px) and (max-width:1600px){.about_content-inner-blob{right:-12%}
}@media(max-width:767px){.about_content-inner-blob{display:none}}.about_content-inner h3{margin:0 0 35px 0;color:#fff;font-family:"Yeseva One",cursive}
.about_content-inner p{margin:0 0 35px 0;font-size:15px;font-family:"Poppins",sans-serif;color:#fff;font-weight:400;line-height:30px}
@media(max-width:991px){.about_content-inner p{font-size:14px;line-height:26px}}.about_content-inner ul{margin:0;padding:0}
.about_content-inner ul li{list-style:none;display:inline-block}.about_content-inner ul li a{display:block;padding:15px 30px;text-decoration:none;color:#fff;border-radius:7px;transition:all .3s ease;border:1px solid #60636f;background:transparent}
.about_content-inner ul li a.active{background-color:#5d78ff;border:1px solid transparent}
.about_content-inner ul li a:hover{border:1px solid #425fee;background:#425fee;box-shadow:0 8px 20px rgba(56,87,241,0.3);transform:scale(1.03)}
.about_content-inner ul li:not(:last-child){margin-right:10px}@media(max-width:375px){.about_content-inner ul li:not(:last-child){margin-bottom:10px}
}.service{position:relative;overflow:hidden;padding-bottom:275px}.service__background{position:absolute;top:0;right:0;z-index:-1;width:61%}
.service__background svg{width:100%;height:100%}.service__background_shape{position:absolute;bottom:-10%;left:-7%;height:465px;width:410px}
.service__background_shape svg{width:100%}@media(max-width:991px){.service__background_shape{display:none}
}.service__background_pattern{position:absolute;bottom:4%;left:6%;animation:move_top 5s infinite linear}
@media(max-width:991px){.service__background_pattern{display:none}}.service__header{margin-bottom:50px}
.service__header span{font-size:20px;font-family:"Poppins",sans-serif;color:#282f49;position:relative;display:block;margin:0 0 20px 30px}
.service__header span::before{position:absolute;content:"";height:2px;width:20px;top:50%;left:-20px;transform:translateX(-50%);background:#282f49}
.service__header h1{margin:0 0 30px 0;font-size:50px;font-family:"Yeseva One",cursive;color:#282f49;line-height:50px}
@media(max-width:767px){.service__header h1{font-size:39px}}.service__header p{margin:0;font-family:"Poppins",sans-serif;color:#282f49;font-size:18px;line-height:26px}
.service__slider_item{margin:15px;padding:30px;box-shadow:0 20px 40px 0 rgba(50,65,141,0.12);border-radius:20px;background:#fff}
.service__slider_item-icon{height:110px;width:125px;position:relative}.service__slider_item-icon .icon-background{position:absolute;top:0;right:0}
.service__slider_item-icon .icon{position:absolute;left:0;bottom:0;z-index:1}.service__slider_item h4{font-size:24px;font-family:"Yeseva One",cursive;color:#282f49;margin:40px 0 30px 0}
.service__slider_item p{font-family:"Poppins",sans-serif;color:#7e7e8a;font-size:16px;line-height:30px;margin:0}
.service__slider .slick-list{overflow:visible}.service__slider .slick-dots{display:flex;align-items:center;margin:0;padding:0;justify-content:center}
.service__slider .slick-dots li{margin:0;list-style:none;height:8px;width:8px}.service__slider .slick-dots li:not(:last-child){margin-right:15px}
.service__slider .slick-dots li button{cursor:pointer;height:100%;width:100%;background:#c2c8cc;border-radius:50%;transition:all .3s ease;display:block;padding:0;text-indent:-9999px}
.service__slider .slick-dots li button:focus{outline:0}.service__slider .slick-dots li button:hover{background:#5d78ff}
.service__slider .slick-dots li.slick-active{height:10px;width:10px;border-radius:50%}
.service__slider .slick-dots li.slick-active button{background-color:#5d78ff}.service__slider .slick-slide:focus{outline:0}
.portfolio{padding:100px 0 135px 0}@media(max-width:991px){.portfolio{padding:100px 0}
}.portfolio__header{margin-bottom:80px}@media(max-width:767px){.portfolio__header{margin-bottom:50px}
}.portfolio__header span{display:block;font-size:20px;font-family:"Poppins",sans-serif;margin:0 0 30px 0}
.portfolio__header h1{margin:0 0 20px 0;font-size:55px;font-family:"Yeseva One",cursive;color:#282f49;line-height:1}
@media(max-width:767px){.portfolio__header h1{font-size:40px}}.portfolio__element{display:flex;flex-wrap:wrap}
.portfolio__element .portfolio__element_left .portfolio__element_left-item-thumb,.portfolio__element .portfolio__element_left .portfolio__element_right-item-thumb,.portfolio__element .portfolio__element_right .portfolio__element_left-item-thumb,.portfolio__element .portfolio__element_right .portfolio__element_right-item-thumb{border-radius:20px;overflow:hidden;transition:all .3s ease}
.portfolio__element .portfolio__element_left .portfolio__element_left-item-thumb img,.portfolio__element .portfolio__element_left .portfolio__element_right-item-thumb img,.portfolio__element .portfolio__element_right .portfolio__element_left-item-thumb img,.portfolio__element .portfolio__element_right .portfolio__element_right-item-thumb img{height:100%;width:100%}
.portfolio__element .portfolio__element_left .portfolio__element_left-item-content span,.portfolio__element .portfolio__element_left .portfolio__element_right-item-content span,.portfolio__element .portfolio__element_right .portfolio__element_left-item-content span,.portfolio__element .portfolio__element_right .portfolio__element_right-item-content span{font-family:"Poppins",sans-serif;color:#7e7e8a;font-size:18px;line-height:1;display:block;margin:28px 0 20px 0}
.portfolio__element .portfolio__element_left .portfolio__element_left-item-content h3,.portfolio__element .portfolio__element_left .portfolio__element_right-item-content h3,.portfolio__element .portfolio__element_right .portfolio__element_left-item-content h3,.portfolio__element .portfolio__element_right .portfolio__element_right-item-content h3{margin:0 0 20px 0}
.portfolio__element .portfolio__element_left .portfolio__element_left-item-content h3 a,.portfolio__element .portfolio__element_left .portfolio__element_right-item-content h3 a,.portfolio__element .portfolio__element_right .portfolio__element_left-item-content h3 a,.portfolio__element .portfolio__element_right .portfolio__element_right-item-content h3 a{font-family:"Yeseva One",cursive;color:#282f49;font-size:25px;line-height:40px;transition:all .3s ease;display:block}
.portfolio__element .portfolio__element_left .portfolio__element_left-item-content h3 a:hover,.portfolio__element .portfolio__element_left .portfolio__element_right-item-content h3 a:hover,.portfolio__element .portfolio__element_right .portfolio__element_left-item-content h3 a:hover,.portfolio__element .portfolio__element_right .portfolio__element_right-item-content h3 a:hover{text-decoration:none;color:#5d78ff}
.portfolio__element .portfolio__element_left .portfolio__element_left-item-content .see-more-btn,.portfolio__element .portfolio__element_left .portfolio__element_right-item-content .see-more-btn,.portfolio__element .portfolio__element_right .portfolio__element_left-item-content .see-more-btn,.portfolio__element .portfolio__element_right .portfolio__element_right-item-content .see-more-btn{text-decoration:none;display:inline-block;font-family:"Poppins",sans-serif;color:#5d78ff;position:relative;font-size:17px;margin:0 0 0 30px;transform:scaleY(0);transform-origin:bottom center;transition:all .3s ease}
.portfolio__element .portfolio__element_left .portfolio__element_left-item-content .see-more-btn::before,.portfolio__element .portfolio__element_left .portfolio__element_right-item-content .see-more-btn::before,.portfolio__element .portfolio__element_right .portfolio__element_left-item-content .see-more-btn::before,.portfolio__element .portfolio__element_right .portfolio__element_right-item-content .see-more-btn::before{position:absolute;content:"";height:2px;width:20px;background:#5d78ff;top:50%;left:-30px;transform:translateY(-50%)}
.portfolio__element_left{width:50%}@media(max-width:767px){.portfolio__element_left{width:100%;margin-bottom:30px}
}.portfolio__element_left-item{padding:0 60px 0 0;transition:.3s ease}@media(max-width:767px){.portfolio__element_left-item{padding:0}
}.portfolio__element_left-item:not(:last-child){margin-bottom:80px}@media(max-width:767px){.portfolio__element_left-item:not(:last-child){margin-bottom:40px}
}.portfolio__element_left-item:nth-child(1){width:80%}@media(max-width:767px){.portfolio__element_left-item:nth-child(1){width:100%}
}.portfolio__element_left-item:hover .portfolio__element_left-item-thumb{box-shadow:0 20px 40px 0 rgba(50,65,141,0.12)}
.portfolio__element_left-item:hover .portfolio__element_left-item-content .see-more-btn{transform:scaleY(1)}
.portfolio__element_right{width:50%}@media(max-width:767px){.portfolio__element_right{width:100%}
}.portfolio__element_right-item{padding:0 60px 0 0;transition:.3s ease}@media(max-width:767px){.portfolio__element_right-item{padding:0}
}.portfolio__element_right-item:not(:last-child){margin-bottom:80px}@media(max-width:767px){.portfolio__element_right-item:not(:last-child){margin-bottom:40px}
}.portfolio__element_right-item:nth-child(2){width:80%;margin-left:auto}@media(max-width:767px){.portfolio__element_right-item:nth-child(2){width:100%}
}.portfolio__element_right-item:hover .portfolio__element_right-item-thumb{box-shadow:0 20px 40px 0 rgba(50,65,141,0.12)}
.portfolio__element_right-item:hover .portfolio__element_right-item-content .see-more-btn{transform:scaleY(1)}
.resume{position:relative;margin-bottom:100px;padding:150px 0}@media(max-width:991px){.resume{margin-bottom:40px;padding:100px 0}
}.resume__background{background:#1b2031;height:100%;width:100%;position:absolute;top:0;left:0;transform:skewY(-2deg)}
.resume__heading{margin-right:100px;position:sticky;position:-webkit-sticky;top:150px}
@media(max-width:991px){.resume__heading{margin-right:0;margin-bottom:30px}}.resume__heading span{font-size:20px;font-family:"Poppins",sans-serif;color:#fff;position:relative;display:block;margin:0 0 20px 30px}
.resume__heading span::before{position:absolute;content:"";height:2px;width:20px;top:50%;left:-20px;transform:translateX(-50%);background:#fff}
.resume__heading h1{margin:0 0 30px 0;font-size:55px;font-family:"Yeseva One",cursive;color:#fff;line-height:50px}
.resume__heading p{margin:0;font-family:"Poppins",sans-serif;color:#fff;font-size:18px;line-height:34px}
.resume__heading ul{margin:40px 0 0 0;padding:0}.resume__heading ul.nav-tabs{border-bottom:0}
.resume__heading ul li{list-style:none;display:inline-block}.resume__heading ul li a{padding:15px 40px;text-decoration:none;display:block;background:#fff;color:#282f49;font-family:"Poppins",sans-serif;font-size:16px;transition:all .3s ease}
@media(max-width:375px){.resume__heading ul li a{padding:15px 28px}}.resume__heading ul li a:hover{background:#425fee;color:#fff;box-shadow:0 8px 20px 0 rgba(56,87,241,0.3)}
.resume__heading ul li a.active{background:#5d78ff;color:#fff;box-shadow:0 8px 20px 0 rgba(56,87,241,0.3)}
.resume__heading ul li a.active:hover{background:#425fee}.resume__heading ul li:nth-child(1) a{border-radius:7px 0 0 7px}
.resume__heading ul li:nth-child(2){margin-left:-5px}.resume__heading ul li:nth-child(2) a{border-radius:0 7px 7px 0}
.resume__education_item{background:#fff;padding:30px;border-radius:20px;margin-bottom:30px;box-shadow:0 20px 40px 0 rgba(50,65,141,0.12)}
.resume__education_item span{color:#5d78ff;display:block;font-size:16px;font-family:"Poppins",sans-serif;margin:0 0 20px 30px;position:relative;line-height:1}
.resume__education_item span::before{position:absolute;content:"";height:2px;width:20px;top:50%;left:-20px;transform:translateX(-50%);background:#282f49}
.resume__education_item h4{font-size:25px;font-family:"Yeseva One",cursive;color:#282f49;margin:0 0 30px 0;line-height:1}
.resume__education_item p{line-height:34px;font-family:"Poppins",sans-serif;color:#7e7e8a;font-size:16px;margin:0}
.skill{position:relative;padding:100px 0 150px 0}@media(max-width:991px){.skill{padding:100px 0}
}.skill__background_shape{position:absolute;bottom:2%;right:2%;height:300px;width:300px}
.skill__background_shape svg{width:100%}@media(max-width:991px){.skill__background_shape{display:none}
}.skill__background_pattern{position:absolute;bottom:5%;right:10%;height:100px;width:100px;animation:move_top 3s infinite linear}
.skill__background_pattern img{height:100%;width:100%}@media(max-width:991px){.skill__background_pattern{display:none}
}.skill__thumb{position:relative}@media(max-width:767px){.skill__thumb{margin-bottom:30px}
}.skill__thumb_pattern{position:absolute;top:-11%;left:-17%;z-index:-1;height:150px;animation:move_top 4s infinite linear}
.skill__thumb_pattern img{height:100%;width:100%}.skill__thumb_image{margin-right:7%}
@media(max-width:991px){.skill__thumb_image{margin-right:0}}.skill__thumb_image img{height:100%;width:100%}
.skill__thumb_image img.skill_mask_svg{mask:url(../images/skill/skill-mask-svg.svg);-webkit-mask-repeat:no-repeat;-webkit-mask-size:contain;-webkit-mask-position:center center}
.skill__progress{margin-left:40px}@media(max-width:991px){.skill__progress{margin-left:0;margin-top:30px}
}.skill__progress_heading{margin-bottom:40px}.skill__progress_heading span{font-size:20px;font-family:"Poppins",sans-serif;color:#282f49;position:relative;display:block;margin:0 0 20px 30px}
.skill__progress_heading span::before{position:absolute;content:"";height:2px;width:20px;top:50%;left:-20px;transform:translateX(-50%);background:#282f49}
.skill__progress_heading h1{margin:0 0 30px 0;font-size:50px;font-family:"Yeseva One",cursive;color:#282f49;line-height:50px}
.skill__progress_heading p{margin:0;font-family:"Poppins",sans-serif;color:#282f49;font-size:18px;line-height:26px}
.skill__progress_item.js-animation{transition:all .3s ease}.skill__progress_item.js-animation .progress{width:100%;background:#e7eef2;height:10px;border-radius:5px}
.skill__progress_item.js-animation .progress .progress-bar{border-radius:5px;position:relative;animation:animate-positive 3000ms;background:#5d78ff}
.skill__progress_item:not(:last-child){margin-bottom:30px}.skill__progress_item h6{display:inline-block;font-family:"Poppins",sans-serif;font-weight:400;font-size:16px}
.skill__progress_item .progress-value{float:right;font-family:"Poppins",sans-serif;font-size:16px;font-weight:400}
@keyframes animate-positive{0%{width:0}}.testimonial{background:#f1f6f9;position:relative;padding:170px 0 150px 0}
@media(max-width:991px){.testimonial{padding:100px 0}}.testimonial__background_shape{position:absolute;width:100%;top:0;left:0}
.testimonial__background_shape svg{height:100%;width:100%}.testimonial__background_shape svg path{fill:#fff}
@media(max-width:400px){.testimonial__background_shape{display:none}}.testimonial__header{margin-bottom:50px}
.testimonial__header span{display:block;font-size:20px;font-family:"Poppins",sans-serif;margin:0 0 30px 0}
.testimonial__header h1{margin:0;font-size:55px;font-family:"Yeseva One",cursive;color:#282f49;line-height:1}
@media(max-width:767px){.testimonial__header h1{font-size:40px}}.testimonial__slider_item{background:#fff;border-radius:20px;padding:60px 50px;margin:30px 25px 60px}
@media(max-width:767px){.testimonial__slider_item{margin:30px 0 40px;padding:50px 30px}
}.testimonial__slider_item-rating{margin:0 0 25px 0;padding:0}.testimonial__slider_item-rating li{list-style:none;display:inline-block}
.testimonial__slider_item-rating li i{color:#ffc219}.testimonial__slider_item-content{margin:0 0 40px 0;font-family:"Poppins",sans-serif;font-size:17px;line-height:35px;color:#282f49}
.testimonial__slider_item-author{margin:0 0 0 35px;font-family:"Poppins",sans-serif;color:#7e7e8a;font-size:16px;line-height:1;position:relative}
.testimonial__slider_item-author span{font-weight:bold;color:#282f49}.testimonial__slider_item-author:before{position:absolute;content:"";height:2px;width:20px;left:-35px;top:50%;transform:translateY(-50%);background:#282f49}
.testimonial__slider .slick-dots{display:flex;align-items:center;margin:0;padding:0;justify-content:center}
.testimonial__slider .slick-dots li{margin:0;list-style:none;height:8px;width:8px}
.testimonial__slider .slick-dots li:not(:last-child){margin-right:15px}.testimonial__slider .slick-dots li button{cursor:pointer;height:100%;width:100%;background:#c2c8cc;border-radius:50%;transition:all .3s ease;display:block;padding:0;text-indent:-9999px}
.testimonial__slider .slick-dots li button:focus{outline:0}.testimonial__slider .slick-dots li button:hover{background:#5d78ff}
.testimonial__slider .slick-dots li.slick-active{height:10px;width:10px;border-radius:50%}
.testimonial__slider .slick-dots li.slick-active button{background-color:#5d78ff}
.testimonial__slider .slick-slide:focus{outline:0}.blog{position:relative;margin-bottom:30px}
.blog__shape{position:absolute;top:11%;right:12%;height:300px;width:300px}.blog__shape img{animation:rotate 25s infinite linear;height:100%;width:100%}
@media(max-width:991px){.blog__shape{display:none}}.blog__header span{font-size:20px;font-family:"Poppins",sans-serif;color:#282f49;position:relative;display:block;margin:0 0 20px 30px}
.blog__header span::before{position:absolute;content:"";height:2px;width:20px;top:50%;left:-20px;transform:translateX(-50%);background:#282f49}
.blog__header h1{margin:0 0 30px 0;font-size:50px;font-family:"Yeseva One",cursive;color:#282f49;line-height:50px}
.blog__header p{margin:0;font-family:"Poppins",sans-serif;color:#282f49;font-size:18px;line-height:26px}
.blog__header_button{position:relative;width:100%;height:100%}.blog__header_button a{position:absolute;bottom:0;right:0;display:inline-block;padding:15px 40px;background:#fff;border:1px solid #e4e6eb;border-radius:7px;font-family:"Poppins",sans-serif;text-decoration:none;font-size:17px;color:#282f49;transition:all .3s ease;transform:scale(1)}
@media(max-width:991px){.blog__header_button a{position:relative;margin-top:30px}
}.blog__header_button a:hover{background:#5d78ff;border:1px solid transparent;color:#fff;transform:scale(1.03);box-shadow:0 8px 20px rgba(56,87,241,0.3)}
.blog__item{position:relative;margin-bottom:20px}@media(max-width:991px){.blog__item{margin-bottom:130px}
}.blog__item-thumb{width:90%;height:100%;overflow:hidden;border-radius:20px}@media(max-width:991px){.blog__item-thumb{width:80%;margin:0 auto}
}.blog__item-thumb img{height:100%;width:100%;transition:all .3s ease}.blog__item-content{background:#fff;position:absolute;bottom:-100px;left:40px;padding:30px;border-radius:20px;box-shadow:0 20px 40px 0 rgba(50,65,141,0.12)}
@media(max-width:991px){.blog__item-content{left:0}}.blog__item-content span{font-size:16px;font-family:"Poppins",sans-serif;color:#5d78ff;margin:0 0 20px 40px;position:relative;display:block;line-height:1}
.blog__item-content span::before{position:absolute;content:"";top:50%;left:-40px;height:1px;width:20px;background:#282f49;transform:translateY(-50%)}
.blog__item-content a{font-size:22px;font-family:"Yeseva One",cursive;line-height:30px;color:#282f49;margin:0;display:block;text-decoration:none;transition:all .3s ease}
.blog__item-content a:hover{color:#5d78ff}.blog__item:hover .blog__item-thumb img{transform:scale(1.1)}
.blog .mobile{display:none}@media(max-width:991px){.blog .desktop{display:none}.blog .mobile{display:block;text-align:center}
}@keyframes rotate{from{-webkit-transform:rotate(0)}to{-webkit-transform:rotate(360deg)}
}.contact{background:#1b2031;position:relative;margin-top:150px}.contact__background_shape{position:absolute;width:100%;top:0;left:0}
.contact__background_shape svg{height:100%;width:100%}.contact__background_shape svg path{fill:#fff}
@media(max-width:575px){.contact__background_shape{display:none}}.contact__cta{background:#5d78ff;padding:100px 80px;border-radius:20px;display:flex;align-items:flex-end;margin-top:-110px;flex-wrap:wrap;position:relative;overflow:hidden;z-index:5}
.contact__cta .shape-1{position:absolute;z-index:-1;transform:rotate(-20deg);top:-43%;left:2%;animation:moveLeft 5s infinite linear}
.contact__cta .shape-1 svg path{fill:#fff;opacity:.07}.contact__cta .shape-2{position:absolute;z-index:-1;transform:rotate(80deg);top:12%;right:-9%;width:235px;height:208px;animation:move_top 3s infinite linear}
.contact__cta .shape-2 svg{height:100%;width:100%}.contact__cta .shape-2 svg path{fill:#fff;opacity:.07}
@media(max-width:991px){.contact__cta{padding:70px 40px}}.contact__cta_content{width:60%}
@media(max-width:991px){.contact__cta_content{width:100%;text-align:center;margin-bottom:20px}
}.contact__cta_content span{font-family:"Poppins",sans-serif;color:#fff;font-size:20px;display:block;line-height:1;margin:0 0 20px 40px;position:relative}
@media(max-width:991px){.contact__cta_content span{display:inline-block}}.contact__cta_content span::before{position:absolute;content:"";height:2px;width:20px;left:-40px;top:50%;transform:translateY(-50%);background:#fff}
.contact__cta_content h1{font-family:"Yeseva One",cursive;font-size:55px;color:#fff;margin:0;line-height:1}
@media(max-width:991px){.contact__cta_content h1{font-size:40px}}.contact__cta_action{width:40%}
@media(max-width:991px){.contact__cta_action{width:100%;text-align:center}}.contact__cta_action a{float:right;color:#5d78ff;padding:15px 40px;background:#fff;display:inline-block;text-decoration:none;font-family:"Poppins",sans-serif;border-radius:7px;font-size:16px;transition:all .3s ease}
@media(max-width:991px){.contact__cta_action a{float:none}}.contact__cta_action a:hover{background:#768bf3;color:#fff}
.contact__widget{padding:100px 0}@media(max-width:991px){.contact__widget{text-align:center;padding:50px 0}
}@media(max-width:991px){.contact__widget_sitemap{margin:40px 0}}.contact__widget_sitemap h3{font-family:"Poppins",sans-serif;color:#fff;margin:0 0 40px 0;line-height:1;font-size:24px}
@media(max-width:991px){.contact__widget_sitemap h3{margin:0 0 20px 0}}.contact__widget_sitemap ul{margin:0;padding:0}
.contact__widget_sitemap ul li{list-style:none}.contact__widget_sitemap ul li a{text-decoration:none;display:block;color:#fff;transition:all .3s ease;font-family:"Poppins",sans-serif;font-size:16px}
.contact__widget_sitemap ul li a:hover{color:#5d78ff}.contact__widget_sitemap ul li:not(:last-child){margin-bottom:10px}
.contact__widget_address h3{font-family:"Poppins",sans-serif;color:#fff;margin:0 0 40px 0;line-height:1;font-size:24px}
@media(max-width:991px){.contact__widget_address h3{margin:0 0 20px 0}}.contact__widget_address ul{margin:0;padding:0}
.contact__widget_address ul li{list-style:none}.contact__widget_address ul li a{text-decoration:none;display:block;color:#fff;transition:all .3s ease;font-family:"Poppins",sans-serif;font-size:16px}
.contact__widget_address ul li a i{margin-right:15px}.contact__widget_address ul li a:hover{color:#5d78ff}
.contact__widget_address ul li:not(:last-child){margin-bottom:10px}.contact__widget_address ul li p{color:#fff;margin:0}
.contact__widget_address ul li p i{margin-right:20px;font-size:17px}.contact__footer{padding:50px 0;border-top:1px solid rgba(255,255,255,0.2)}
@media(max-width:991px){.contact__footer{text-align:center}}.contact__footer_copy p{color:#fff;font-size:16px;font-family:"Poppins",sans-serif;margin:0}
.contact__footer_social ul{margin:0;padding:0;float:right}@media(max-width:991px){.contact__footer_social ul{margin:20px auto 0;float:none}
}.contact__footer_social ul li{list-style:none;display:inline-block}.contact__footer_social ul li a{display:block;text-decoration:none;color:#fff;font-size:20px;transition:all .3s ease}
.contact__footer_social ul li a:hover{color:#5d78ff}.contact__footer_social ul li:not(:last-child){margin-right:25px}
@keyframes moveLeft{0%,100%{-webkit-transform:translateX(0) rotate(-20deg);transform:translateX(0) rotate(-20deg)}
50%{-webkit-transform:translateX(15px) rotate(-20deg);transform:translateX(15px) rotate(-20deg)}
}.blog__item{position:relative;margin-bottom:120px}.blog__item-thumb{width:90%;height:100%}
@media(max-width:991px){.blog__item-thumb{width:80%;margin:0 auto}}.blog__item-thumb img{height:100%;width:100%;border-radius:20px}
.blog__item-content{background:#fff;position:absolute;bottom:-100px;left:40px;padding:30px;border-radius:20px;box-shadow:0 20px 40px 0 rgba(50,65,141,0.12)}
@media(max-width:991px){.blog__item-content{left:0;width:100%}}.blog__item-content span{font-size:16px;font-family:"Poppins",sans-serif;color:#5d78ff;margin:0 0 20px 40px;position:relative;display:block;line-height:1}
.blog__item-content span::before{position:absolute;content:"";top:50%;left:-40px;height:1px;width:20px;background:#282f49;transform:translateY(-50%)}
.blog__item-content a{font-size:22px;font-family:"Yeseva One",cursive;line-height:30px;color:#282f49;margin:0;display:block;text-decoration:none;transition:all .3s ease}
.blog__item-content a:hover{color:#5d78ff}.blog__pagination{width:100%;position:relative;height:80px;margin-top:40px}
.blog__pagination .pagination{position:absolute;top:0;left:50%;transform:translateX(-50%)}
.blog__pagination .pagination li{height:80px;width:80px;box-shadow:0 20px 40px 0 rgba(50,65,141,0.12);border-radius:10px;position:relative;transition:all .3s ease;cursor:pointer}
.blog__pagination .pagination li a{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);text-decoration:none;font-family:"Yeseva One",cursive;font-size:20px;color:#282f49;transition:all .3s ease}
.blog__pagination .pagination li a svg path{fill:#000}.blog__pagination .pagination li:hover{background:#5d78ff}
.blog__pagination .pagination li:hover a{color:#fff}.blog__pagination .pagination li:hover a svg path{fill:#fff}
.blog__pagination .pagination li:not(:last-child){margin-right:34px}.blog__pagination .pagination li.active{background:#5d78ff}
.blog__pagination .pagination li.active a{color:#fff}.blog__pagination .pagination li.active a svg path{fill:#fff}
@media(max-width:991px){.section{padding:50px 0}}.section .svg-img{position:absolute;top:30%;left:-7%;animation:move_top 5s infinite linear}
@media(max-width:991px){.section .svg-img{display:none}}.section .animate-shape{position:absolute;top:29%;right:4%;height:300px;width:300px}
.section .animate-shape svg{width:100%}@media(max-width:991px){.section .animate-shape{display:none}
}.section .animate-pattern{position:absolute;top:46%;right:12%;height:100px;width:100px;animation:move_top 3s infinite linear}
.section .animate-pattern img{height:100%;width:100%}@media(max-width:991px){.section .animate-pattern{display:none}
}.section .singleBlog__feature{margin:0 100px}@media(max-width:991px){.section .singleBlog__feature{margin:0}
}.section .singleBlog__feature img{height:100%;width:100%;border-radius:20px}.section .singleBlog__content{margin:0 200px 0 200px}
@media(max-width:991px){.section .singleBlog__content{margin:0}}.section .singleBlog__content p{font-family:"Poppins",sans-serif;font-size:16px;line-height:30px;margin-bottom:40px;color:#7e7e8a}
.section .singleBlog__content dl,.section .singleBlog__content ul,.section .singleBlog__content ol{margin-top:0;margin-bottom:40px;font-family:"Poppins",sans-serif;font-size:16px;line-height:30px;color:#7e7e8a}
.section .singleBlog__content blockquote{font-family:"Poppins",sans-serif;font-size:16px;line-height:30px;color:#282f49;padding:30px;background:#fff;box-shadow:0 20px 40px 0 rgba(50,65,141,0.07);border-radius:5px;margin:0 0 50px 0}
.section .singleBlog__content blockquote cite{display:block}.section .singleBlog__content .blog-section{margin-bottom:60px}
.section .singleBlog__content .blog-section img{float:right;mask:url("../images/hero/hero-mask-svg.png");-webkit-mask-repeat:no-repeat;-webkit-mask-size:contain;-webkit-mask-position:center center}
@media(max-width:767px){.section .singleBlog__content .blog-section img{width:100%;float:none;margin-bottom:40px}
}.section .singleBlog__content .blog-section h3{font-family:"Yeseva One",cursive;color:#282f49;font-size:25px;line-height:40px;margin:0 0 20px 0}
.breadCrumb{padding:200px 0 0;position:relative}.breadCrumb .svg-img{position:absolute;bottom:-35%;left:-7%;animation:move_top 5s infinite linear}
@media(max-width:991px){.breadCrumb .svg-img{display:none}}.breadCrumb .animate-shape{position:absolute;bottom:-60%;right:2%;height:300px;width:300px}
.breadCrumb .animate-shape svg{width:100%}@media(max-width:991px){.breadCrumb .animate-shape{display:none}
}.breadCrumb .animate-pattern{position:absolute;bottom:-40%;right:10%;height:100px;width:100px;animation:move_top 3s infinite linear}
.breadCrumb .animate-pattern img{height:100%;width:100%}@media(max-width:991px){.breadCrumb .animate-pattern{display:none}
}@media(max-width:991px){.breadCrumb{padding:120px 0 0}}.breadCrumb__title{font-size:48px;font-family:"Yeseva One",cursive;color:#282f49;line-height:1;margin:0 0 30px 0}
@media(max-width:767px){.breadCrumb__title{font-size:36px}}@media(max-width:575px){.breadCrumb__title{font-size:30px}
}.breadCrumb .breadcrumb{background:0;margin:0;padding:0}.breadCrumb .breadcrumb-item a{font-family:"Poppins",sans-serif;color:#282f49;font-size:17px;text-decoration:none}
@media(max-width:575px){.breadCrumb .breadcrumb-item a{font-size:14px}}@media(max-width:400px){.breadCrumb .breadcrumb-item a{font-size:12px}
}.breadCrumb .breadcrumb-item.active{font-family:"Poppins",sans-serif;color:#282f49}
@media(max-width:575px){.breadCrumb .breadcrumb-item.active{font-size:14px}}@media(max-width:400px){.breadCrumb .breadcrumb-item.active{font-size:12px}
}.breadCrumb .breadcrumb-item+.breadcrumb-item::before{content:"-";color:#282f49;font-size:17px;padding-right:10px}
@media(max-width:575px){.breadCrumb .breadcrumb-item+.breadcrumb-item::before{font-size:14px}
}@media(max-width:400px){.breadCrumb .breadcrumb-item+.breadcrumb-item::before{padding-right:0}
}.breadCrumb .breadcrumb-item+.breadcrumb-item{padding-left:10px}@media(max-width:400px){.breadCrumb .breadcrumb-item+.breadcrumb-item{padding-right:0}
}.classicBlog__item{position:relative;margin-bottom:150px}.classicBlog__item-thumb{width:88%;height:100%}
@media(max-width:991px){.classicBlog__item-thumb{width:80%;margin:0 auto}}.classicBlog__item-thumb img{height:100%;width:100%;border-radius:20px}
.classicBlog__item-content{margin-right:18px;background:#fff;position:absolute;bottom:-100px;left:40px;padding:30px 50px 30px 40px;border-radius:20px;box-shadow:0 20px 40px 0 rgba(50,65,141,0.12)}
@media(max-width:991px){.classicBlog__item-content{left:0}}@media(max-width:575px){.classicBlog__item-content{padding:25px}
}.classicBlog__item-content span{font-size:16px;font-family:"Poppins",sans-serif;color:#5d78ff;margin:0 0 20px 40px;position:relative;display:block;line-height:1}
@media(max-width:575px){.classicBlog__item-content span{font-size:14px}}.classicBlog__item-content span::before{position:absolute;content:"";top:50%;left:-40px;height:1px;width:20px;background:#282f49;transform:translateY(-50%)}
.classicBlog__item-content a{font-size:28px;font-family:"Yeseva One",cursive;line-height:40px;color:#282f49;margin:0;display:block;text-decoration:none;transition:all .3s ease}
@media(max-width:575px){.classicBlog__item-content a{font-size:20px;line-height:28px}
}.classicBlog__item-content a:hover{color:#5d78ff}.classicBlog__pagination{width:100%;position:relative;height:80px;margin-top:40px}
@media(max-width:991px){.classicBlog__pagination{margin-bottom:100px}}.classicBlog__pagination .pagination{position:absolute;top:15px;left:0}
.classicBlog__pagination .pagination li{height:80px;width:80px;box-shadow:0 20px 40px 0 rgba(50,65,141,0.12);border-radius:10px;position:relative;transition:all .3s ease;cursor:pointer}
.classicBlog__pagination .pagination li a{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);text-decoration:none;font-family:"Yeseva One",cursive;font-size:20px;color:#282f49;transition:all .3s ease}
.classicBlog__pagination .pagination li a svg path{fill:#000}.classicBlog__pagination .pagination li:hover{background:#5d78ff}
.classicBlog__pagination .pagination li:hover a{color:#fff}.classicBlog__pagination .pagination li:hover a svg path{fill:#fff}
.classicBlog__pagination .pagination li:not(:last-child){margin-right:34px}.classicBlog__pagination .pagination li.active{background:#5d78ff}
.classicBlog__pagination .pagination li.active a{color:#fff}.classicBlog__pagination .pagination li.active a svg path{fill:#fff}
.classicBlog__sidebar:not(:first-child){margin:40px 0 20px 0}.classicBlog__sidebar .form-search .form-group{position:relative}
.classicBlog__sidebar .form-search .form-group .form-control{height:70px;padding:10px 30px;border:0;box-shadow:0 20px 40px rgba(50,65,141,0.12);border-radius:10px;color:#7e7e8a;font-family:"Poppins",sans-serif;font-size:16px}
.classicBlog__sidebar .form-search .form-group .search-icon{position:absolute;right:30px;top:50%;transform:translateY(-50%);display:block;line-height:1}
.classicBlog__sidebar .sidebar-title{font-size:24px;color:#282f49;font-family:"Yeseva One",cursive;margin:0 0 25px 0}
.classicBlog__sidebar .list-item{margin:0;padding:0}.classicBlog__sidebar .list-item li{display:block;list-style:none}
.classicBlog__sidebar .list-item li a{display:block;padding:7px 0;font-size:16px;font-family:"Poppins",sans-serif;color:#7e7e8a;transition:all .3s ease}
.classicBlog__sidebar .list-item li a span{float:right}.classicBlog__sidebar .list-item li a:hover{color:#5d78ff;text-decoration:none}
.classicBlog__sidebar .latest-post-item{display:flex;align-items:center;padding:15px 0}
.classicBlog__sidebar .latest-post-item .thumb{width:100px;border-radius:10px;overflow:hidden}
.classicBlog__sidebar .latest-post-item .thumb img{height:100%;width:100%}.classicBlog__sidebar .latest-post-item .content{width:calc(100% - 100px);padding-left:30px}
.classicBlog__sidebar .latest-post-item .content span{display:block;font-family:"Poppins",sans-serif;font-size:16px;color:#5d78ff;margin:0 0 0 40px;position:relative}
.classicBlog__sidebar .latest-post-item .content span::before{position:absolute;content:"";top:50%;left:-40px;transform:translateY(-50%);height:2px;width:20px;background:#282f49}
.classicBlog__sidebar .latest-post-item .content h5{margin:0}.classicBlog__sidebar .latest-post-item .content h5 a{font-family:"Yeseva One",cursive;color:#282f49;font-size:20px;line-height:30px;transition:all .3s ease}
.classicBlog__sidebar .latest-post-item .content h5 a:hover{text-decoration:none;color:#5d78ff}
.contact__info_item{box-shadow:0 20px 40px 0 rgba(50,65,141,0.12);padding:50px 40px;border-radius:20px}
.contact__info_item-icon{height:110px;width:125px;position:relative;margin-bottom:35px}
.contact__info_item-icon .icon-background{position:absolute;top:0;right:0}.contact__info_item-icon .icon{position:absolute;left:0;bottom:0;z-index:1}
.contact__info_item h4{margin:0 0 30px 0;font-family:"Yeseva One",cursive;font-size:25px;color:#282f49}
.contact__info_item p{margin:0;font-size:16px;line-height:34px;color:#7e7e8a;font-family:"Poppins",sans-serif}
.contact-form{padding:50px 0 100px 0}.contact-form-title h4{font-family:"Yeseva One",cursive;color:#282f49;margin:0 0 30px 0;font-size:35px}
.contact-form-input .form-group{margin-bottom:30px}.contact-form-input .form-group .form-control{border:1px solid #aeb8be;border-radius:7px;padding:10px 30px;font-size:14px;font-family:"Poppins",sans-serif;color:#282f49}
.contact-form-input .form-group .form-control::placeholder{color:#282f49}.contact-form-input .form-group .form-control:-ms-input-placeholder{color:#282f49}
.contact-form-input .form-group .form-control::-ms-input-placeholder{color:#282f49}
.contact-form-input .form-group .form-control:focus{outline:0;box-shadow:none}.contact-form-input .form-group input.form-control{height:55px;border:1px solid #aeb8be}
.contact-form-input .form-check{padding:0;margin-bottom:30px;margin-top:-10px}.contact-form-input .form-check-input{padding:0;height:initial;width:initial;margin-bottom:0;display:none;cursor:pointer}
.contact-form-input .form-check-label{position:relative;cursor:pointer}.contact-form-input .form-check-label::before{content:"";-webkit-appearance:none;background-color:transparent;border:1px solid #aeb8be;height:20px;width:20px;border-radius:4px;display:inline-block;position:relative;vertical-align:middle;cursor:pointer;margin-right:8px}
.contact-form-input .form-check input:checked+label:after{content:"";display:block;position:absolute;top:6px;left:7px;width:6px;height:12px;border:solid #0079bf;border-width:0 2px 2px 0;transform:rotate(45deg)}
.contact-form-input .contact-form-btn{padding:20px 45px;display:inline-block;font-size:16px;font-family:"Poppins",sans-serif;background:#5d78ff;border-radius:7px;color:#fff;transition:all .3s ease}
.contact-form-input .contact-form-btn:hover{background:#425fee;box-shadow:0 8px 20px rgba(56,87,241,0.3);transform:scale(1.03);text-decoration:none}
.contact-form #map{border-radius:30px;height:100%}@media(max-width:991px){.contact-form #map{height:400px;margin-top:60px}
}.case-details{padding:200px 0 100px 0}@media(max-width:991px){.case-details{padding:120px 0 0}
}.case-details .svg-img{position:absolute;top:30%;left:-7%;animation:move_top 5s infinite linear}
@media(max-width:991px){.case-details .svg-img{display:none}}.case-details .animate-shape{position:absolute;top:29%;right:4%;height:300px;width:300px}
.case-details .animate-shape svg{width:100%}@media(max-width:991px){.case-details .animate-shape{display:none}
}.case-details .animate-pattern{position:absolute;top:46%;right:12%;height:100px;width:100px;animation:move_top 3s infinite linear}
.case-details .animate-pattern img{height:100%;width:100%}@media(max-width:991px){.case-details .animate-pattern{display:none}
}.case-details-title{font-size:50px;line-height:60px;font-family:"Yeseva One",cursive;color:#282f49;margin:0 0 50px 0}
.case-details p{font-family:"Poppins",sans-serif;color:#7e7e8a;font-size:16px;line-height:34px;margin:0 0 30px 0}
.case-details-info{display:flex;justify-content:space-around;background:#282f49;border-radius:20px;padding:65px 0;margin:70px 0 80px 0}
@media(max-width:575px){.case-details-info{flex-direction:column;padding:30px 0}}
.case-details-info .case-details-info-item{position:relative}.case-details-info .case-details-info-item:not(:last-child)::before{position:absolute;content:"";width:1px;height:100%;top:0;right:-80px;background:rgba(255,255,255,0.1)}
@media(max-width:991px){.case-details-info .case-details-info-item:not(:last-child)::before{right:-45px}
}@media(max-width:767px){.case-details-info .case-details-info-item:not(:last-child)::before{right:-15px}
}@media(max-width:575px){.case-details-info .case-details-info-item:not(:last-child)::before{display:none}
}@media(max-width:575px){.case-details-info .case-details-info-item:not(:last-child){margin-bottom:30px}
}.case-details-info h5{margin:0 0 10px 0;font-size:20px;color:#fff;font-family:"Yeseva One",cursive}
.case-details-info p{margin:0}.case-details-service{margin-bottom:90px}.case-details-service h3{font-family:"Yeseva One",cursive;color:#282f49;font-size:25px;margin:0 0 25px 0}
.case-details-service p{margin:0}.case-details-thumb{margin:0 0 90px 0;border-radius:30px;overflow:hidden}
.case-details-thumb img{height:100%;width:100%}.case-details-nav{display:flex;justify-content:space-between;align-items:center;margin-top:50px}
.case-details-nav .previous .icon,.case-details-nav .next .icon{line-height:0}.case-details-nav .previous .content span,.case-details-nav .next .content span{display:block;font-family:"Poppins",sans-serif;color:#7e7e8a;font-size:15px;line-height:1;margin-bottom:5px}
.case-details-nav .previous .content h5,.case-details-nav .next .content h5{font-family:"Yeseva One",cursive;font-size:20px;margin:0;line-height:1}
.case-details-nav .previous .content h5 a,.case-details-nav .next .content h5 a{text-decoration:none;color:#282f49;transition:all .3s ease}
.case-details-nav .previous .content h5 a:hover,.case-details-nav .next .content h5 a:hover{color:#5d78ff}
.case-details-nav .previous{display:flex;align-items:center}.case-details-nav .previous .icon{margin-right:15px}
@media(max-width:575px){.case-details-nav .social{display:none}}.case-details-nav .social ul{margin:0;padding:0}
.case-details-nav .social ul li{list-style:none;display:inline-block}.case-details-nav .social ul li a{display:block;text-decoration:none;color:#7e7e8a;font-size:20px;transition:all .3s ease}
.case-details-nav .social ul li a:hover{color:#5d78ff}.case-details-nav .social ul li:not(:last-child){margin-right:25px}
.case-details-nav .next{display:flex;align-items:center}.case-details-nav .next .icon{margin-left:15px}
.case-details-nav .next .content{text-align:right}
//site color variables
$body-color: #fff;
$primary-color: #5D78FF;
$secondary-color: #282F49;
$text-color: #9D9EA5;
$text-color-dark: #00113E;
$site-ease: cubic-bezier(0.36, 0.03, 0, 0.91);
$gray: #F7F9FF;
$dark-gray: #646569;

// solid colors
$white: #fff;
$black: #000;

// Font Variables
$primary-font: 'Yeseva One', cursive;
$secondary-font: 'Poppins', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900|Yeseva+One&display=swap');

// body{
//   font-family: $primary-font;
//   font-size: 16px;
//   color: $text-color;
// }

// h1, h2, h3, h4, h5, h6{
//   font-family: $secondary-font;
//   font-weight: 600;
//   color: $text-color-dark;
// }

// p{
//   font-size: 16px;
//   color: $text-color;
// }

// h1{
//   font-size: 60px;
// }

// h2{
//   font-size: 50px;
// }

// h3{
//   font-size: 30px;
// }

// h4{
//   font-size: 25px;
// }

// h5{
//   font-size: 18px;
// }

// h6{
//   font-size: 15px;
//   font-weight: 300;
// }
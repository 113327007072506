// html{
//   scroll-behavior: smooth;
// }

body {
  background: $body-color;
}

.section{
  padding: 100px 0;
}

